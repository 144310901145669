
import {
  UspsBlock,
  FreeForm,
  AccordionList,
  InfoBoxList,
  H1Title,
} from '~/components/organisms'
export default {
  components: {
    UspsBlock,
    FreeForm,
    AccordionList,
    InfoBoxList,
    H1Title,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
}
