<template>
  <layout
    :key="uniquePageKey"
    :header="header"
    :footer="footer"
    :country="country"
    :hide-usps="true"
    :show-breadcrumbs="false"
    :background-color="
      isProduct && shouldUseCustomDenomination ? 'bg-white' : ''
    "
  >
    <client-only>
      <web-to-app-banner
        v-if="showWebToAppBanner"
        :banner-viewed-cookie-name="bannerCookieName"
        :banner-viewed-cookie-duration="bannerCookieDuration"
        :banner-title="webToAppTitle"
        :banner-subtitle="webToAppSubtitle"
      >
        <recharge-faster-banner-image v-if="isCategory" />
        <web-to-app-mobile-banner-image v-if="isProduct" :location="pageType" />
      </web-to-app-banner>
    </client-only>
    <div>
      <loading v-if="$fetchState.pending" />
      <product
        v-else-if="isProduct && shouldUseCustomDenomination"
        :page-data="contentfulPageData"
      />
      <brand v-else-if="isProduct" :page-data="contentfulPageData" />
      <category
        v-else-if="isCategory"
        :page-data="categoryPageData"
        :allowed-countries="taxon.countries"
        :refresh-loading="loading"
        @refresh-category-page="refreshCategory"
      />
      <countries v-else-if="isCountries" :countries-data="countriesData" />
      <secondary v-else-if="isLegal" :page-data="legalData" />
      <portal-target :name="portals.PORTAL_NAME_RTR_PHONE_NUMBER_INPUT" />
      <portal-target :name="portals.PORTAL_NAME_SWITCH_COUNTRY" />
      <portal-target :name="portals.PORTAL_SUGGESTION_COUNTRY" />
    </div>
  </layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalizeProduct, GeoLock } from '@/normalizers/recommerce/product'
import { mutatePath } from '@/utils/mutatePath'
import {
  LEGAL_PAGE,
  PRODUCT_PAGE,
  CATEGORY_PAGE,
  COUNTRIES_PAGE,
  SECONDARY_PAGE,
} from '@/utils/constants/category-brand-legal'
import createPageMixin from '~/mixins/page'
import Layout from '~/components/layout/default'
import {
  RechargeFasterBannerImage,
  WebToAppMobileBannerImage,
} from '~/components/molecules'
import { Loading, WebToAppBanner } from '~/components/organisms'
import { recommerceToImageByType, contentfulToImage } from '~/utils/image'
import {
  Category,
  Secondary,
  Brand,
  Countries,
  Product,
} from '~/components/pages'
import { firstOfCollection } from '~/utils/collection'
import { portals } from '~/enums'
import { hydrateCache } from '~/apis/clients/rest/recommerce/cache'
import webToAppTreatment from '~/mixins/web-to-app-treatment'

export const TAXON_PRODUCTS_LIMIT = 100

export default {
  name: 'CategoryBrandLegal',
  components: {
    WebToAppMobileBannerImage,
    RechargeFasterBannerImage,
    WebToAppBanner,
    Category,
    Secondary,
    Product,
    Brand,
    Loading,
    Layout,
    Countries,
  },
  mixins: [createPageMixin({ cloudfrontCache: false }), webToAppTreatment],
  data() {
    return {
      pageType: null,
      taxon: null,
      product: null,
      portals,
      redirectTo: null,
      loading: false,
      webToAppTreatment: false,
      bannerCookieName: '',
      bannerCookieDuration: '',
    }
  },
  async fetch() {
    try {
      await Promise.all([this.fetchRecommerceData(), this.fetchLegal()])
    } catch (e) {
      this.$sentry.captureException(e)
      this.$nuxt.error({ statusCode: 500, message: e.message })
      return
    }

    // If during the fetch we found that we're on a product page for global country
    if (this.redirectTo !== null) {
      this.$nuxt.context.redirect(this.redirectTo)
      return
    }

    if (!this.pageType)
      this.$nuxt.error({ statusCode: 404, message: 'Not found' })
  },
  computed: {
    ...mapGetters('context', [
      'customerCountry',
      'redeemCountryEqualsCustomerCountry',
      'isGlobalCountry',
    ]),
    ...mapGetters('contentful', ['sharedMenu', 'country']),
    isCategory() {
      return this.pageType === CATEGORY_PAGE
    },
    isProduct() {
      return this.pageType === PRODUCT_PAGE
    },
    isCountries() {
      return this.contentfulPageData.slug === COUNTRIES_PAGE
    },
    isLegal() {
      return this.pageType === LEGAL_PAGE
    },
    isSecondaryPage() {
      return [COUNTRIES_PAGE].includes(this.contentfulPageData.slug)
    },
    shouldUseCustomDenomination() {
      return this.contentfulPageData.isCustomDenomination
    },
    categoryPageData() {
      const heroBanner = this.getSection('SectionCategoryBanner')
      if (!heroBanner) return null

      return {
        sectionCategoryBanner: {
          image: contentfulToImage(heroBanner.banner),
          imageMobile: contentfulToImage(heroBanner.bannerMobile),
          showBreadcrumbs: false,
          title: heroBanner.title,
        },
        sectionBrandList: [
          {
            brands: this.taxon.products.flatMap(product =>
              product.isAvailableOnWeb
                ? [
                    {
                      logo: recommerceToImageByType(product, 'product_card'),
                      slug: product.slug,
                      title: product.nameInCategoryPage || product.name,
                      countries: product.countries,
                    },
                  ]
                : []
            ),
            categoryPage: { slug: this.contentfulPageData.slug },
          },
        ],
        slug: this.taxon.slug,
        sectionFreeForm: this.getSections('SectionFreeForm'),
      }
    },
    legalData() {
      return {
        sectionH1Title: this.getSection('SectionH1Title'),
        sectionInfoBoxList: this.getSection('SectionInfoBoxList')
          ?.itemsCollection,
        sectionFreeForm: this.getSections('SectionFreeForm'),
        sectionAccordion: this.getSection('SectionAccordion'),
      }
    },
    countriesData() {
      return {
        countriesHeroBanner: this.getSection('SectionHeroBanner'),
        titleDescription: this.getSection('SectionTitleAndDescription'),
        appBanner: this.getSection('SectionAppBanner'),
      }
    },
  },
  mounted() {
    if (
      !this.$fetchState?.pending &&
      this.$fetchState.error === null &&
      !this.isLegal
    ) {
      hydrateCache(
        {
          recommerceLocale: this.recommerceLocale,
          endpoint: this.isProduct ? 'productBySlug' : 'taxonBySlug',
          params: this.isProduct
            ? { productSlug: this.slug }
            : {
                taxonSlug: this.slug,
                limit: TAXON_PRODUCTS_LIMIT,
              },
          redemptionCountry: this.countryCode,
          currency: this.currency.code,
        },
        this.isProduct ? this.product : this.taxon
      )
    }
  },
  methods: {
    async fetchRecommerceData() {
      const data = await this.$recommerce('xBySlug')({
        slug: this.slug,
        limit: TAXON_PRODUCTS_LIMIT,
      })
      if (data === null) return

      if (data?.type === 'taxon') {
        await this.fetchCategory(data.data)
      } else if (data?.type === 'product') {
        await this.fetchProduct(data.data)
      }
    },
    async refreshCategory() {
      this.loading = true
      try {
        const data = await this.$recommerce('taxonBySlug')({
          taxonSlug: this.slug,
        })
        if (data === null) return
        this.taxon = data
      } catch (e) {
        if (process.env.NODE_ENV === 'development') throw e
        this.$sentry.configureScope(scope => {
          scope.setTag('refreshCategory', true)
        })
        this.$sentry.captureException(e)
      } finally {
        this.loading = false
      }
    },
    async fetchCategory(taxon) {
      const { data: categoryResponse } = await this.$contentful(
        'categoryGmpPage'
      )({
        slug: this.slug,
        locale: this.$store.getters['context/cmsLocale'],
        countryCode: this.$store.getters['context/countryCode'],
      })
      const category = firstOfCollection(
        categoryResponse.categoryPageCollection
      )

      if (!category || !taxon) return

      this.contentfulPageData = category
      this.contentfulPageCountryOverrideData =
        firstOfCollection(
          categoryResponse.categoryPageCountryOverrideCollection
        ) || {}
      this.taxon = taxon
      this.pageType = CATEGORY_PAGE
    },
    async fetchProduct(product) {
      if (!product) return
      if (!product.isAvailableOnWeb) {
        this.$nuxt.error({ statusCode: 404, message: 'Not found' })
        return
      }

      const category = product.categories.find(c => {
        return this.sharedMenu.map(({ slug }) => slug).includes(c.slug)
      })

      if (this.isGlobalCountry) {
        this.redirectTo = mutatePath(this.$route.path, {
          selectedSlug: category.slug,
        })
        return
      }

      this.contentfulPageData = {
        ...normalizeProduct(product),
        countries: product.countries,
        availableForThisCustomerCountry: product.countries
          .map(c => c.code)
          .includes(this.customerCountry.code),
      }

      this.contentfulPageData.category = category
      const variants = this.contentfulPageData.productListComponent.productList
      if (
        !variants ||
        variants.length === 0 ||
        (this.contentfulPageData.geoLock === GeoLock.DISABLED &&
          !this.redeemCountryEqualsCustomerCountry)
      ) {
        const similarProducts = await this.fetchSimilarProducts(
          category,
          product.slug
        )
        this.contentfulPageData.similarProducts = similarProducts
      } else {
        await this.fetchProductPricings(variants)
      }

      this.product = product
      this.pageViewAttrs = {
        orderDeliveryMethod: this.contentfulPageData.type,
        productInfo: {
          brandCategory: category.slug,
          brandTitle: product.name,
        },
      }
      this.pageType = PRODUCT_PAGE
    },
    async fetchLegal() {
      const { data: legalPageResponse } = await this.$contentful('legalPage')({
        slug: this.slug,
        locale: this.$store.getters['context/cmsLocale'],
      })

      const legal = firstOfCollection(legalPageResponse.legalPageCollection)

      if (!legal) return

      this.contentfulPageData = legal
      this.pageType = this.isSecondaryPage ? SECONDARY_PAGE : LEGAL_PAGE
    },
    async fetchSimilarProducts(category, currentSlug) {
      if (!category) return []
      const taxon = await this.$recommerce('taxonBySlug')({
        taxonSlug: category.slug,
        limit: TAXON_PRODUCTS_LIMIT,
      })

      return taxon.products.flatMap(product => {
        if (product.slug === currentSlug) return []
        return [
          {
            logo: recommerceToImageByType(product, 'product_card'),
            slug: product.slug,
            title: product.name,
          },
        ]
      })
    },
    fetchProductPricings(products) {
      const currency = this.$store.getters['context/currency']
      const merchant = this.$store.getters['context/merchant']
      return this.$store.dispatch('products/fetchProducts', {
        products,
        currency,
        merchant,
        country: this.country.abv,
      })
    },
  },
}
</script>
