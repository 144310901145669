import { mapGetters } from 'vuex'

interface IMixinDataObject {
  webToAppTreatment: boolean
  bannerCookieName: string
  bannerCookieDuration: string
}
export default {
  data(): IMixinDataObject {
    return {
      webToAppTreatment: false,
      bannerCookieName: '',
      bannerCookieDuration: '',
    }
  },
  computed: {
    ...mapGetters('context', ['countryCode', 'customerCountry']),
    isCategory(): boolean {
      return this.isCategory
    },
    isProduct(): boolean {
      return this.isProduct
    },
    isVariantPage(): boolean {
      return !!this.variant
    },
    categoryPage(): string {
      if (this.isVariantPage) {
        return this.category.slug
      }
      if (this.isCategory) {
        return this.contentfulPageData?.slug
      }
      return this.contentfulPageData?.category.slug
    },
    webToAppTitle(): string {
      if (this.isCategory) {
        return this.$t('organisms.web_to_app_banner.recharge_faster')
      }
      if (this.isProduct || this.isVariantPage) {
        return this.$t('organisms.web_to_app_banner.recharge_brand_faster', {
          product: this.product.nameInCategoryPage,
        })
      }
      return ''
    },
    productCardImageUrl(): string {
      if (this.isVariantPage) {
        return this.contentfulPageData.productCardImage.path
      }
      return this.contentfulPageData.productCardImage.url
    },
    webToAppSubtitle(): string {
      return this.$t('organisms.web_to_app_banner.get_mobile_app')
    },
    showWebToAppBanner(): boolean {
      return this.webToAppTreatment && this.isMobile
    },
  },
  _methods: {
    fetchExperiment(): void {
      if (!this.mobileOs) return

      const blockedCategoryPage = ['none']
      const blockedCountry = ['NG']
      const cookieName = 'web_to_app_banner_viewed'
      const cookieDuration = 4

      if (
        blockedCategoryPage.includes(this.categoryPage) ||
        blockedCountry.includes(this.countryCode.toUpperCase()) ||
        blockedCountry.includes(this.customerCountry.code.toUpperCase())
      ) {
        return
      }

      this.webToAppTreatment = true
      this.bannerCookieName = cookieName
      this.bannerCookieDuration = cookieDuration
    },
  },
  get methods() {
    return this._methods
  },
  set methods(value) {
    this._methods = value
  },
  watch: {
    contentfulPageData: {
      handler(): void {
        this.fetchExperiment()
      },
      deep: true,
    },
  },
}
