export const LEGAL_PAGE = 'legal'
export const PRODUCT_PAGE = 'product'
export const MOBILE_TOPUP = 'mobile-top-up'
export const CATEGORY_PAGE = 'category'
export const COUNTRIES_PAGE = 'countries'
export const CARRIERS_PAGE = 'carriers'
export const SECONDARY_PAGE = 'secondary_page'

// The list of countries without RTR products on the moment of this TEMPORARY fix
// See OP-1306
// Used in category and carriers page components
export const NO_RTR_COUNTRIES = [
  'AD',
  'AO',
  'AT',
  'AU',
  'BA',
  'BE',
  'BG',
  'BN',
  'BT',
  'BY',
  'CH',
  'CK',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'EE',
  'EH',
  'ER',
  'FI',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GI',
  'GL',
  'GP',
  'GQ',
  'GR',
  'GU',
  'HK',
  'HR',
  'HU',
  'IE',
  'IL',
  'IR',
  'IS',
  'JO',
  'JP',
  'KI',
  'KP',
  'KR',
  'KZ',
  'LI',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MC',
  'ME',
  'MH',
  'MK',
  'ML',
  'MN',
  'MO',
  'MP',
  'MR',
  'MT',
  'MU',
  'MV',
  'NA',
  'NC',
  'NF',
  'NL',
  'NO',
  'NU',
  'NZ',
  'OM',
  'PF',
  'PM',
  'PN',
  'PW',
  'RE',
  'RS',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SM',
  'SO',
  'ST',
  'SY',
  'TD',
  'TK',
  'TM',
  'TV',
  'TW',
  'VA',
  'VI',
  'WF',
]
