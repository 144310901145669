
import { mapGetters } from 'vuex'
import { PropOptions } from 'vue'
import {
  CategoryBanner,
  BrandList,
  SearchCarriers,
} from '@/components/organisms'
import { Row, Column, Container } from '~/components/grid'
import { hashBrands } from '~/utils/hashBrands'
import {
  AlertBlock,
  AppBanner,
  HlrInput,
  SwitchCountryButton,
} from '~/components/molecules'

import GLOBAL_COUNTRY from '~/utils/constants/global-country.json'
import { ICountryLangItem } from '~/models/components/CountryLangList'
import {
  MOBILE_TOPUP,
  NO_RTR_COUNTRIES,
} from '~/utils/constants/category-brand-legal'
import { SectionAppBanner } from '~/apis/clients/graphql/types/contentful'
import { ICarriersData } from '~/models/components/Carriers'
import { IBrandList } from '~/models/components/BrandList'
import { NO_HLR_COUNTRIES } from '~/utils/constants/hlr'

const NUMBER_OF_GLOBAL_CARRIERS = 12

export default {
  name: 'Carriers',
  components: {
    AlertBlock,
    AppBanner,
    BrandList,
    CategoryBanner,
    Column,
    Container,
    HlrInput,
    Row,
    SearchCarriers,
    SwitchCountryButton,
  },
  props: {
    carriersData: {
      type: Object,
      required: true,
    } as PropOptions<ICarriersData>,
    allowedCountries: {
      type: Array,
      default: () => [],
    } as PropOptions<ICountryLangItem[]>,
    direction: {
      type: String,
      default: 'ltr',
    } as PropOptions<'ltr' | 'rtl'>,
  },
  data() {
    return {
      globalCarriers: null,
    }
  },
  async fetch() {
    try {
      this.globalCarriers = await this.$recommerce('taxonBySlug')(
        {
          taxonSlug: MOBILE_TOPUP,
        },
        {
          'Redemption-Country': GLOBAL_COUNTRY.code.toLowerCase(),
        }
      )
    } catch (e) {
      this.$notifications.pushError({
        text: this.$t('pages.carriers.general_error') as string,
      })
      this.$sentry.captureError(e)
    }
  },
  computed: {
    ...mapGetters('context', ['country', 'isGlobalCountry']),
    isHLRAvailable() {
      // The list of countries without RTR products on the moment of this TEMPORARY fix
      // See OP-1306
      // Moved to constants file in OP-1088, HLR is used in carriers as well
      return (
        !this.isGlobalCountry &&
        !NO_HLR_COUNTRIES.includes(this.country.code.toUpperCase()) &&
        !NO_RTR_COUNTRIES.includes(this.country.code.toUpperCase())
      )
    },
    appBannerData(): SectionAppBanner {
      return this.carriersData.appBanner
    },
    showAlertBlock(): boolean {
      const brands = this.carriersData.sectionBrandList[0]?.brands
      return !brands || brands.length === 0
    },
    switchTranslationKey(): string {
      return this.showAlertBlock ? 'change_country_to' : 'to_be_used_in'
    },
    filteredCarriersData(): IBrandList[] {
      const carriersData = this.carriersData.sectionBrandList[0]?.brands
      return [
        {
          brands:
            this.country.code === GLOBAL_COUNTRY.code.toLowerCase()
              ? carriersData.slice(0, NUMBER_OF_GLOBAL_CARRIERS)
              : carriersData,
          categoryPage: this.carriersData.sectionBrandList[0]?.categoryPage,
        },
      ]
    },
  },
  methods: {
    hash(brands): string {
      return hashBrands(brands)
    },
    onHlrInput(phoneNumberInfo): void {
      const slug = phoneNumberInfo?.product?.slug
      if (!slug) return
      this.$router.push({ path: slug })
    },
  },
}
