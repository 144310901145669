
import { mapGetters } from 'vuex'
import { Container } from '@/components/grid'
import { AppBanner, TitleDescription } from '@/components/molecules'
import { HeroBanner, SearchCountries } from '~/components/organisms'

import {
  SectionAppBanner,
  SectionHeroBanner,
  SectionTitleAndDescription,
} from '~/apis/clients/graphql/types/contentful'

export default {
  name: 'Countries',
  components: {
    SearchCountries,
    TitleDescription,
    AppBanner,
    Container,
    HeroBanner,
  },
  props: {
    countriesData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters('context', ['direction']),
    currentLanguage(): string {
      return this.$route.params.language
    },
    appBannerData(): SectionAppBanner {
      return this.countriesData.appBanner
    },
    heroBannerData(): SectionHeroBanner {
      return this.countriesData.countriesHeroBanner
    },
    titleDescriptionData(): SectionTitleAndDescription {
      return this.countriesData.titleDescription
    },
    isMobile(): boolean {
      return this.$mq === 'sm' || this.$mq === 'md'
    },
    heroBannerUrl(): string {
      if (this.isMobile) {
        return this.heroBannerData.bannerMobile
      }
      return this.heroBannerData.banner
    },
  },
  methods: {
    openMobileCarriers(countryCode): void {
      window.location.href = `${window.location.origin}/${this.currentLanguage}/${countryCode}/mobile-top-up`
    },
  },
}
