<template>
  <div class="flex-1 flex pt-8 justify-center">
    <div v-if="pageSection" :class="sectionClasses">
      <activation-info
        v-if="
          pageSection.sectionInfoBlocks && pageSection.sectionInfoBlocks.title
        "
        :title="pageSection.sectionInfoBlocks.title"
        :info-blocks="pageSection.sectionInfoBlocks.infoBlocks"
      />
      <accordion-list
        v-if="pageSection.sectionAccordion"
        :title="
          pageSection.sectionAccordion.title || $t('organisms.faq_list.faq_all')
        "
        :accordion-items="pageSection.sectionAccordion.accordionItems"
      />
      <cdp-container
        id="cdp-thankyou-footer-desktop-banner"
        :cdp-country="$route.params.country"
        :cdp-language="$route.params.language"
        :cdp-product-type="productType"
        :cdp-product-category="productCategory"
        cdp-label="GMP"
        class="w-full mt-7 mb-15 px-6 md:px-10"
        device-type="desktop"
      />
      <free-form
        v-if="terms"
        :title="terms.title"
        :content-block="terms.contentBlock"
      />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { CdpContainer } from '~/components/renderers'
import { AccordionList, FreeForm, ActivationInfo } from '~/components/organisms'
export default {
  name: 'OrderBrandInfo',
  components: { CdpContainer, FreeForm, AccordionList, ActivationInfo },
  props: {
    pageSection: VueTypes.object.def({}),
    sectionClasses: VueTypes.string.def(''),
    productCategory: VueTypes.string.def(''),
    productType: VueTypes.string.def(''),
  },
  computed: {
    terms() {
      return Array.isArray(this.pageSection.sectionFreeForm)
        ? this.pageSection.sectionFreeForm[0]
        : this.pageSection.sectionFreeForm
    },
  },
}
</script>
