<template>
  <div class="flex flex-col flex-1 pt-8">
    <brand-banner
      v-if="pageData.sectionBrandBanner"
      :has-active-promotion="pageData.sectionBrandBanner.hasActivePromotion"
      :image="pageData.sectionBrandBanner.image"
      :image-mobile="pageData.sectionBrandBanner.imageMobile"
      :title="pageData.sectionBrandBanner.title"
      :description="pageData.sectionBrandBanner.description"
      :logo="pageData.sectionBrandBanner.logo"
      :category="pageData.category"
      :data-test="`brand-brandBanner`"
    />
    <free-form
      v-if="pageData.outOfStock"
      :key="pageData.outOfStock.title"
      :title="pageData.outOfStock.title"
      :content-block="pageData.outOfStock.contentBlock"
      :data-test="`brand-freeForm1`"
    />

    <template v-if="pageData.outOfStock">
      <brand-list
        v-for="(section, index) in pageData.sectionBrandList"
        :key="`brand-list-${index}`"
        :brands="section.brands"
        :title="section.title"
        :category-page="section.categoryPage"
        :data-test="`brand-brandList`"
      />
    </template>

    <alert-block
      v-if="showAlertBlock"
      :title="alertTitle"
      :description="alertDescription"
      icon="warning-stroke"
      :data-test="alertDataTest"
    />
    <container v-if="!showProducts">
      <row wrap>
        <column v-if="!hideCountrySelector" class="w-full sm:w-1/2 lg:w-1/3">
          <switch-country-button
            v-if="showCountrySwitch"
            :class="!showProducts ? 'mb-8' : ''"
            :display-text="$t(`pages.brand.change_country.${geolockSwitchKey}`)"
            :country="countryToShow"
            :redirect-to-country="isDisabled"
            :items="pageData.countries"
            :data-test="`brand-switchCountryButton1`"
            @click="handleCountryClick"
          />
        </column>
        <column class="w-full sm:w-1/2 lg:w-1/3 mt-4 sm:mt-0">
          <currency-dropdown
            v-if="productCurrencies.length > 1"
            :currencies="productCurrencies"
            :default-currency="defaultCurrency"
            :data-test="`brand-currencyDropdown1`"
            @input="onSelectCurrency"
          />
        </column>
      </row>
    </container>
    <product-list
      v-if="showProducts"
      id="web_to_app_banner_scroll_target"
      :class="`order-${productList.index}`"
      :products="productList.productList"
      :ordered-tabs="productList.productTabs"
      :brand-info="productList.brandInfo"
      :cdp-info="cdpInfo"
      :max-len="200"
      :phone-number-info="phoneNumberInfo"
      :title="productList.title"
      :disable-purchase="isDisabledPurchase"
      :add-list-to-cart-tracking="true"
      :selected-currency="selectedCurrency"
      :data-test="`brand-productList`"
      @phone-edit="onPhoneEdit"
      @product-click="onProductClick"
      @tab-change="handleTabChange"
    >
      <container>
        <row wrap>
          <column v-if="!hideCountrySelector" class="w-full sm:w-1/2 lg:w-1/3">
            <switch-country-button
              :display-text="
                $t(`pages.brand.change_country.${geolockSwitchKey}`)
              "
              :display-text-classes="'text-lg'"
              :country="countryToShow"
              :redirect-to-country="isDisabled"
              :items="pageData.countries"
              :data-test="`brand-switchCountryButton2`"
              @click="handleCountryClick"
            />
          </column>
          <column
            v-if="productCurrencies.length > 1"
            class="w-full sm:w-1/2 lg:w-1/3 mt-4 sm:mt-auto mb-1"
          >
            <currency-dropdown
              :currencies="productCurrencies"
              :default-currency="defaultCurrency"
              :data-test="`brand-currencyDropdown2`"
              @input="onSelectCurrency"
            />
          </column>
          <column
            v-if="cdpShowCountrySelectorBanner"
            :class="cdpCountrySelectorConfig.class"
          >
            <cdp-container
              id="cdp-brand-countryselector-desktop-banner"
              :cdp-country="$route.params.country"
              :cdp-language="$route.params.language"
              cdp-label="GMP"
              :cdp-page-type="cdpPageType"
              :cdp-category="pageData.category.slug"
              :cdp-brand="pageData.slug"
              :cdp-columns="cdpCountrySelectorConfig.columns"
              device-type="desktop"
            />
          </column>
          <column class="w-full">
            <p class="text-black mt-2 font-bold text-lg -mb-2">{{
              productListTitle
            }}</p>
          </column>
        </row>
      </container>
    </product-list>
    <similar-products
      v-else-if="isGlobalMarketplace && pageData.category"
      :title="similarProductsTitle"
      :product="pageData"
      :data-test="`brand-similarProducts`"
    />
    <activation-info
      v-if="pageData.sectionInfoBlocks && pageData.sectionInfoBlocks.title"
      :class="`order-${pageData.sectionInfoBlocks.index}`"
      :title="pageData.sectionInfoBlocks.title"
      :info-blocks="pageData.sectionInfoBlocks.infoBlocks"
      no-truncate
      :data-test="`brand-activationInfo`"
    />
    <accordion-list
      v-if="pageData.sectionAccordion"
      :class="`order-${pageData.sectionAccordion.index}`"
      :title="pageData.sectionAccordion.title || $t('general.faq')"
      :accordion-items="pageData.sectionAccordion.accordionItems"
      :data-test="`brand-accordionList`"
    />
    <template v-if="pageData.sectionFreeForm">
      <free-form
        v-for="freeForm in pageData.sectionFreeForm"
        :key="freeForm.title"
        :class="`order-${freeForm.index}`"
        :title="freeForm.title"
        :content-block="freeForm.contentBlock"
        :data-test="`brand-freeForm2`"
      />
    </template>
    <cdp-container
      id="cdp-brand-footer-all-banner"
      class="mb-10 lg:mb-15 mt-2 sm:mt-10 lg:mt-7 px-6 md:px-10 mx-auto w-full container"
      :cdp-country="$route.params.country"
      :cdp-language="$route.params.language"
      cdp-label="GMP"
      :cdp-page-type="cdpPageType"
      :cdp-category="pageData.category.slug"
      :cdp-brand="pageData.slug"
    />
    <usps-block class="order-last" is-last :data-test="`brand-uspsBlock`" />
    <phone-number-modal
      :show="phoneNumberModalVisible"
      :type="phoneNumberModalType"
      :data-test="`phoneNumberModal`"
      @close="() => (phoneNumberModalVisible = false)"
      @phone-number-info-input="onPhoneNumberModalInput"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  AccordionList,
  BrandBanner,
  BrandList,
  UspsBlock,
  FreeForm,
  ProductList,
  PhoneNumberModal,
  ActivationInfo,
  SimilarProducts,
} from '~/components/organisms'
import {
  SwitchCountryButton,
  AlertBlock,
  CurrencyDropdown,
} from '~/components/molecules'
import { GeoLock } from '~/normalizers/recommerce/product'
import { getStorageItem } from '~/services/localstorage'
import { Row, Column, Container } from '~/components/grid'
import { CdpContainer } from '~/components/renderers'
import { CDP_PAGE_TYPES } from '~/enums/customer-data-platform'

export default {
  components: {
    CdpContainer,
    AccordionList,
    BrandBanner,
    BrandList,
    UspsBlock,
    FreeForm,
    ProductList,
    PhoneNumberModal,
    SimilarProducts,
    ActivationInfo,
    AlertBlock,
    SwitchCountryButton,
    Row,
    Column,
    Container,
    CurrencyDropdown,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      encodedPhoneNumber: '',
      showCountrySelector: false,
      phoneNumberInfo: {},
      selectedCurrency: {},
      currentProduct: null,
      phoneNumberModalVisible: false,
      iconArrow: {
        icon: 'arrow',
      },
      productCurrencies: [],
    }
  },
  computed: {
    ...mapGetters('context', [
      'redeemCountryEqualsCustomerCountry',
      'customerCountry',
      'countries',
      'currency',
      'currencies',
      'isGlobalMarketplace',
      'lastProductSeenId',
      'country',
    ]),
    ...mapGetters('products', { productPricings: 'all' }),
    ...mapGetters('contentful', { contentfulCountry: 'country' }),
    ...mapGetters('checkout', ['createPaymentPayload']),
    defaultCurrency() {
      const defaultCurrency =
        this.productCurrencies.find(option => this.currency.code === option) ||
        this.productCurrencies.find(
          option => this.country.defaultCurrency === option
        ) ||
        this.productCurrencies.find(
          option => this.customerCountry.defaultCurrency === option
        ) ||
        this.productCurrencies[0]
      return { value: defaultCurrency, label: defaultCurrency }
    },
    phoneNumberModalType() {
      return this.phoneNumberInfo.phoneNumber ? 'change' : 'validate'
    },
    isDisabled() {
      return (
        this.pageData.geoLock === GeoLock.DISABLED &&
        !this.redeemCountryEqualsCustomerCountry
      )
    },
    isDisabledPurchase() {
      return (
        this.pageData.geoLock === GeoLock.DISABLED_PURCHASE &&
        !this.redeemCountryEqualsCustomerCountry
      )
    },
    alertTitle() {
      return this.pageData.geoLock === GeoLock.NO_GEOLOCK
        ? this.$t('pages.brand.alternative_products.alert_title')
        : this.$t(`pages.brand.restricted.${this.geolockAlertKey}.title`)
    },
    alertDescription() {
      return this.pageData.geoLock === GeoLock.NO_GEOLOCK
        ? this.$t('pages.brand.alternative_products.alert_description')
        : this.$t(`pages.brand.restricted.${this.geolockAlertKey}.description`)
    },
    alertDataTest() {
      return this.pageData.geoLock === GeoLock.NO_GEOLOCK
        ? 'product-not-available-locally'
        : 'geolocation-restricted-product'
    },
    similarProductsTitle() {
      return this.pageData.geoLock === GeoLock.NO_GEOLOCK
        ? this.$t('pages.brand.alternative_products.no_geolock_title')
        : this.$t('pages.brand.alternative_products.geolock_title', {
            category: this.pageData.category.name,
          })
    },
    geolockSwitchKey() {
      if (
        this.pageData.geoLock === GeoLock.DISABLED &&
        this.redeemCountryEqualsCustomerCountry
      )
        return 'useable_in'
      if (this.pageData.geoLock === GeoLock.DISABLED) return 'change_country_to'
      return 'to_be_used_in'
    },
    geolockAlertKey() {
      if (
        this.pageData.geoLock === GeoLock.DISABLED &&
        !this.pageData.availableForThisCustomerCountry
      )
        return 'geolockNoRedirect'
      return {
        [GeoLock.NO_GEOLOCK]: 'geolockRedirect',
        [GeoLock.DISABLED]: 'geolockRedirect',
        [GeoLock.DISABLED_PURCHASE]: 'noPurchase',
      }[this.pageData.geoLock]
    },
    countryToShow() {
      return this.pageData.geoLock === GeoLock.DISABLED
        ? this.customerCountry
        : this.country
    },
    showProducts() {
      if (!this.isGlobalMarketplace) return this.productList
      return (
        this.hasVariants &&
        (this.pageData.geoLock !== GeoLock.DISABLED ||
          this.redeemCountryEqualsCustomerCountry)
      )
    },
    showAlertBlock() {
      if (!this.hasVariants) return true
      return (
        this.pageData.geoLock !== GeoLock.NO_GEOLOCK &&
        !this.redeemCountryEqualsCustomerCountry
      )
    },
    hideGeolockedCountrySelector() {
      return (
        this.pageData.geoLock !== GeoLock.NO_GEOLOCK &&
        this.redeemCountryEqualsCustomerCountry
      )
    },
    hideCountrySelector() {
      return (
        this.hideGeolockedCountrySelector || this.pageData.countries.length <= 1
      )
    },
    showCountrySwitch() {
      if (this.pageData.geoLock === GeoLock.NO_GEOLOCK) return true
      if (this.pageData.geoLock === GeoLock.DISABLED_PURCHASE) return true
      if (
        this.pageData.geoLock === GeoLock.DISABLED &&
        this.pageData.availableForThisCustomerCountry
      )
        return true

      return false
    },
    hasVariants() {
      return (
        this.productList.productList && this.productList.productList.length > 0
      )
    },
    productList() {
      return this.pageData.productListComponent
    },
    productListTitle() {
      return this.hideCountrySelector
        ? this.$t(`general.select_value`)
        : this.$t(`pages.brand.product.select_product`)
    },
    storedProductId() {
      return (
        this.createPaymentPayload.product?.productId ||
        this.createPaymentPayload.productId ||
        this.lastProductSeenId
      )
    },
    storedProduct() {
      const productId = this.storedProductId
      const storedPlainProduct = this.productList.productList.find(
        product => product?.productId?.toString() === productId?.toString()
      )
      if (!storedPlainProduct) return null
      return {
        ...storedPlainProduct,
        ...this.productPricings[storedPlainProduct.productId],
      }
    },
    cdpShowCountrySelectorBanner() {
      if (!this.hideCountrySelector || this.productCurrencies.length > 1)
        return true

      return false
    },
    cdpPageType() {
      return CDP_PAGE_TYPES.MULTIPLE_CARDS
    },
    cdpInfo() {
      return {
        brandCategory: this.pageData.category.slug,
        brandSlug: this.pageData.slug,
      }
    },
    cdpCountrySelectorConfig() {
      if (!this.hideCountrySelector && this.productCurrencies.length > 1)
        return {
          class: 'w-full sm:w-1/2 lg:w-1/3 flex-1',
          columns: 1,
        }

      if (this.hideCountrySelector || this.productCurrencies.length < 2)
        return {
          class: 'w-full sm:w-1/2 lg:w-2/3 flex-1',
          columns: 2,
        }

      return {
        class: '',
        columns: 0,
      }
    },
  },
  mounted() {
    this.setPhoneInfo()
    this.setProductCurrencies()
    this.selectedCurrency = this.defaultCurrency
    if (this.pageData.geoLock !== GeoLock.NO_GEOLOCK)
      this.$gtmEnhanced.trackGeolockProduct()
  },
  methods: {
    redirectToCheckout(product, encodedPhoneNumber) {
      const { quantity, productId } = product

      let checkoutUrl = `/checkout?productId=${productId}&quantity=${quantity}`
      if (encodedPhoneNumber) {
        checkoutUrl = `${checkoutUrl}&p=${encodedPhoneNumber}`
      }

      this.$gtmEnhanced.trackAddToCart(product, this.currency, true)
      window.location.href = this.$contextPath(checkoutUrl)
    },
    onSelectCurrency(selectedCurrency) {
      this.selectedCurrency = selectedCurrency
    },
    onProductClick(product) {
      this.currentProduct = product
      this.phoneNumberModalVisible = true
    },
    onPhoneNumberModalInput(encodedPhoneNumber) {
      this.encodedPhoneNumber = encodedPhoneNumber
      this.setPhoneInfo()
      const product =
        this.createPaymentPayload.product ||
        this.currentProduct ||
        this.storedProduct

      if (product) {
        this.redirectToCheckout(
          { ...product, quantity: product.quantity || 1 },
          encodedPhoneNumber
        )
      } else {
        this.phoneNumberModalVisible = false
      }
    },
    setPhoneInfo() {
      const { phoneNumberInfo = {} } = getStorageItem({
        storage: 'recharge-store',
      })
      const country = this.contentfulCountry || {}
      const { dialCode } = phoneNumberInfo

      if (country !== null && country.dialCode !== dialCode) return
      this.phoneNumberInfo = phoneNumberInfo || {}
    },
    handleCountryClick() {
      if (this.pageData.geoLock === GeoLock.NO_GEOLOCK) return
      this.$gtmEnhanced.trackChangeGeolocationProduct()
    },
    onPhoneEdit() {
      this.phoneNumberModalVisible = true
    },
    setProductCurrencies() {
      this.productCurrencies = this.productList.productList.reduce(
        (acc, product) => {
          if (!acc.includes(product.priceCurrency))
            acc.push(product.priceCurrency)
          return acc
        },
        []
      )
    },
    handleTabChange(tab) {
      this.productCurrencies = tab?.currencies
      if (!this.productCurrencies) this.setProductCurrencies()
      if (!this.productCurrencies?.includes(this.selectedCurrency))
        this.selectedCurrency = this.defaultCurrency
    },
  },
}
</script>
