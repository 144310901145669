
import { mapGetters } from 'vuex'
import { UiButton } from '~/components/atoms'
import {
  SwitchCountryButton,
  CurrencyDropdown,
  ProductInfo,
  PhoneNumberCdInput,
  CustomDenomination,
  MainTitle,
} from '~/components/molecules'
import { GeoLock } from '~/normalizers/recommerce/product'
import { Row, Column, Container } from '~/components/grid'
import { Divider, Usps, UiImage } from '~/components/atoms/'
import { uspsConfig } from '~/enums/usps-config'
import {
  UspsBlock,
  Faq,
  BreadcrumbsTrustpilotBar,
} from '~/components/organisms'
import RichTextRenderer from '~/components/renderers/rich-text-renderer/index.vue'
import { centsToUnits } from '~/utils/prices'
import { CdpContainer } from '~/components/renderers'
import { REDEEM_TYPE } from '~/services/topup/enums'
import { CDP_PAGE_TYPES } from '~/enums/customer-data-platform'

export default {
  components: {
    SwitchCountryButton,
    Row,
    Column,
    Container,
    CurrencyDropdown,
    Divider,
    ProductInfo,
    Usps,
    UiImage,
    UspsBlock,
    Faq,
    CustomDenomination,
    UiButton,
    BreadcrumbsTrustpilotBar,
    RichTextRenderer,
    MainTitle,
    PhoneNumberCdInput,
    CdpContainer,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCountrySelector: false,
      phoneNumberInfo: {},
      selectedCurrency: {},
      hasProductsWithRtr: true,
      currentProduct: null,
      iconArrow: {
        icon: 'arrow',
      },
      uspsConfig,
      customDenominationPrice: null,
    }
  },
  computed: {
    ...mapGetters('context', [
      'redeemCountryEqualsCustomerCountry',
      'customerCountry',
      'countries',
      'currency',
      'currencies',
      'isGlobalMarketplace',
      'lastProductSeenId',
      'country',
    ]),
    ...mapGetters('products', { productPricings: 'all' }),
    ...mapGetters('checkout', ['createPaymentPayload']),
    ...mapGetters('products', ['pageViewEventLogged']),
    termsAndConditions() {
      return this.pageData?.sectionFreeForm?.[0]
    },
    currentCurrencyBasedProduct() {
      const currentCurrency =
        this.selectedCurrency.value || this.defaultCurrency.value

      return this.pageData?.productListComponent?.productList?.find(
        variant => variant.priceCurrency === currentCurrency
      )
    },
    customDenomination() {
      return this.currentCurrencyBasedProduct?.customdenomination
    },
    defaultCurrency() {
      const defaultCurrency =
        this.productCurrencies.find(option => this.currency.code === option) ||
        this.productCurrencies.find(
          option => this.country.defaultCurrency === option
        ) ||
        this.productCurrencies.find(
          option => this.customerCountry.defaultCurrency === option
        ) ||
        this.productCurrencies[0]
      return { value: defaultCurrency, label: defaultCurrency }
    },
    phoneReady() {
      const { encodedPhoneNumber } = this.phoneNumberInfo
      return !this.pageData.isRTR || encodedPhoneNumber
    },
    isRedirect() {
      return (
        this.pageData.geoLock === GeoLock.DISABLED &&
        !this.redeemCountryEqualsCustomerCountry
      )
    },
    productCurrencies() {
      return this.pageData?.productListComponent.productList.reduce(
        (acc, product) =>
          acc.includes(product.priceCurrency)
            ? acc
            : [...acc, product.priceCurrency],
        []
      )
    },
    // isDisabledPurchase() {
    //   return (
    //     this.pageData.geoLock === GeoLock.DISABLED_PURCHASE &&
    //     !this.redeemCountryEqualsCustomerCountry
    //   )
    // },
    // alertTitle() {
    //   return this.pageData.geoLock === GeoLock.NO_GEOLOCK
    //     ? this.$t('pages.brand.alternative_products.alert_title')
    //     : this.$t(`pages.brand.restricted.${this.geolockAlertKey}.title`)
    // },
    // alertDescription() {
    //   return this.pageData.geoLock === GeoLock.NO_GEOLOCK
    //     ? this.$t('pages.brand.alternative_products.alert_description')
    //     : this.$t(`pages.brand.restricted.${this.geolockAlertKey}.description`)
    // },
    // alertDataTest() {
    //   return this.pageData.geoLock === GeoLock.NO_GEOLOCK
    //     ? 'product-not-available-locally'
    //     : 'geolocation-restricted-product'
    // },
    // similarProductsTitle() {
    //   return this.pageData.geoLock === GeoLock.NO_GEOLOCK
    //     ? this.$t('pages.brand.alternative_products.no_geolock_title')
    //     : this.$t('pages.brand.alternative_products.geolock_title', {
    //         category: this.pageData.category.name,
    //       })
    // },
    geolockSwitchKey() {
      if (
        this.pageData.geoLock === GeoLock.DISABLED &&
        this.redeemCountryEqualsCustomerCountry
      )
        return 'useable_in'
      if (this.pageData.geoLock === GeoLock.DISABLED) return 'change_country_to'
      return 'to_be_used_in'
    },
    // geolockAlertKey() {
    //   if (
    //     this.pageData.geoLock === GeoLock.DISABLED &&
    //     !this.pageData.availableForThisCustomerCountry
    //   )
    //     return 'geolockNoRedirect'
    //   return {
    //     [GeoLock.NO_GEOLOCK]: 'geolockRedirect',
    //     [GeoLock.DISABLED]: 'geolockRedirect',
    //     [GeoLock.DISABLED_PURCHASE]: 'noPurchase',
    //   }[this.pageData.geoLock]
    // },
    countryToShow() {
      return this.pageData.geoLock === GeoLock.DISABLED
        ? this.customerCountry
        : this.country
    },
    showProducts() {
      return (
        this.hasVariants &&
        (this.pageData.geoLock !== GeoLock.DISABLED ||
          this.redeemCountryEqualsCustomerCountry)
      )
    },
    // showAlertBlock() {
    //   if (!this.hasVariants) return true
    //   return (
    //     this.pageData.geoLock !== GeoLock.NO_GEOLOCK &&
    //     !this.redeemCountryEqualsCustomerCountry
    //   )
    // },
    showCountrySwitch() {
      if (this.pageData.geoLock === GeoLock.NO_GEOLOCK) return true
      if (this.pageData.geoLock === GeoLock.DISABLED_PURCHASE) return true
      if (
        this.pageData.geoLock === GeoLock.DISABLED &&
        this.pageData.availableForThisCustomerCountry
      )
        return true

      return false
    },
    hasVariants() {
      return (
        this.productList.productList && this.productList.productList.length > 0
      )
    },
    productList() {
      return this.pageData.productListComponent
    },
    storedProductId() {
      return (
        this.createPaymentPayload.product?.productId ||
        this.createPaymentPayload.productId ||
        this.lastProductSeenId
      )
    },
    storedProduct() {
      const productId = this.storedProductId
      const storedPlainProduct = this.productList.productList.find(
        product => product?.productId?.toString() === productId?.toString()
      )
      if (!storedPlainProduct) return null
      return {
        ...storedPlainProduct,
        ...this.productPricings[storedPlainProduct.productId],
      }
    },
    categoryCrumb() {
      if (
        this.pageData.category &&
        Object.keys(this.pageData.category).length
      ) {
        return {
          label: this.pageData.category.name,
          title: this.pageData.category.name,
          url: `/${this.pageData.category.slug}`,
        }
      }

      return {}
    },
    isMobile() {
      return this.$mq === 'sm'
    },
    cdpPageType() {
      return CDP_PAGE_TYPES.CUSTOM_DENOMINATIONS
    },
    cdpContainerProductType(): string {
      if (this.pageData.isRTR) {
        return REDEEM_TYPE.RTR
      }
      return REDEEM_TYPE.PIN
    },
  },
  mounted() {
    if (this.pageData.geoLock !== GeoLock.NO_GEOLOCK)
      this.$gtmEnhanced.trackGeolockProduct()

    this.$watch(
      'pageViewEventLogged',
      value => {
        if (value) {
          this.trackProductImpressions()
        }
      },
      { immediate: true }
    )
  },
  methods: {
    onSelectCurrency(selectedCurrency) {
      this.selectedCurrency = selectedCurrency
    },
    // onProductClick(product) {
    //   this.currentProduct = product
    //   this.phoneNumberModalVisible = true
    // },
    // onPhoneNumberModalInput(encodedPhoneNumber) {
    //   this.encodedPhoneNumber = encodedPhoneNumber
    //   this.setPhoneInfo()
    //   const product =
    //     this.createPaymentPayload?.product ||
    //     this.currentProduct ||
    //     this.storedProduct

    //   if (product) {
    //     this.redirectToCheckout(
    //       { ...product, quantity: product.quantity || 1 },
    //       encodedPhoneNumber
    //     )
    //   } else {
    //     this.phoneNumberModalVisible = false
    //   }
    // },
    handleCustomDenomination(customDenominationPrice) {
      this.customDenominationPrice = customDenominationPrice
    },
    onButtonClick() {
      if (!this.customDenominationPrice) return
      const { encodedPhoneNumber = '' } = this.phoneNumberInfo
      const product = this.getCustomDenomProduct(this.customDenominationPrice)

      this.redirectToCheckout(product, encodedPhoneNumber)
    },
    handleCountryClick() {
      if (this.pageData.geoLock === GeoLock.NO_GEOLOCK) return
      this.$gtmEnhanced.trackChangeGeolocationProduct()
    },
    handlePhoneNumberInput(phoneNumberInfo) {
      this.phoneNumberInfo = phoneNumberInfo
    },
    redirectToCheckout(product, encodedPhoneNumber): void {
      const { quantity, productId } = product

      let checkoutUrl = `/checkout?productId=${productId}&quantity=${quantity}&value=${this.customDenominationPrice}`
      if (encodedPhoneNumber) {
        checkoutUrl = `${checkoutUrl}&p=${encodedPhoneNumber}`
      }
      const productImpression = this.getProductImpression(product)
      this.$gtmEnhanced.trackAddToCart(productImpression, this.currency, true)
      window.location.href = this.$contextPath(checkoutUrl)
    },
    // handleTabChange(tab) {
    //   this.productCurrencies = tab?.currencies
    //   if (!this.productCurrencies) this.setProductCurrencies()
    //   if (!this.productCurrencies?.includes(this.selectedCurrency))
    //     this.selectedCurrency = this.defaultCurrency
    // },
    onHowItWorksClicked() {
      const faq = this.$refs?.faq
      if (faq) {
        faq.$el?.scrollIntoView({ behavior: 'smooth' })
        faq.showFirstFaq()
      }
    },
    getCustomDenomProduct(price) {
      return {
        quantity: 1,
        productId: this.currentCurrencyBasedProduct?.productId,
        title: `${this.currentCurrencyBasedProduct?.title} ${centsToUnits(
          this.customDenomination?.minValue
        )} - ${centsToUnits(this.customDenomination.maxValue)} ${
          this.currentCurrencyBasedProduct?.priceCurrency
        }`,
        price: { localAmount: centsToUnits(price) },
      }
    },
    getProductImpression(product) {
      const topupProduct = this.productPricings[product?.productId]
      const productImpression = {
        ...product,
        available: topupProduct?.available,
        productBrand: this.currentCurrencyBasedProduct?.brandTitle,
        productCategory: this.currentCurrencyBasedProduct?.brandCategory,
        brandName: topupProduct?.brand_name,
        // serviceFee - to be added,
        // position - check this once we integrate other prods view with CD,
        parentDenominationType: 'brand', // hard-coded until??...,
        redeemType: topupProduct?.redeem_type,
        productKind: topupProduct?.product_kind,
        scamNotificationType: this.currentCurrencyBasedProduct
          ?.scamNotificationType,

        // duplicating props, as required by trackGmpProductImpressions method
        brandCategory: this.currentCurrencyBasedProduct?.brandCategory,
        brandTitle: this.currentCurrencyBasedProduct?.brandTitle,
        product_kind: topupProduct?.product_kind,
      }

      return productImpression
    },
    trackProductImpressions() {
      // Only tracking custom denom product currently, update once we get mixed products
      const customDenomProduct = this.getCustomDenomProduct(
        this.customDenomination?.minValue
      )
      const productImpression = this.getProductImpression(customDenomProduct)
      this.$gtmEnhanced.trackGmpProductImpressions(
        [productImpression],
        this.currency
      )
    },
  },
}
